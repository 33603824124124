
@import "settings";
@import "foundation";

$red: #d0262f;


body {
  overflow-y: scroll;
  position: static;
}

@font-face {
  font-family: Nexa;
  src: url("../fonts/nexa.otf") format("opentype");
}

@font-face {
  font-family: Nexa;
  font-weight: bold;
  src: url("../fonts/nexabold.otf") format("opentype");
}

iframe {
  width: 100%;
  margin: 0rem;
}

// Forms
input[type="submit"], input[type="button"] {
  @extend .button;
}

.inner-wrap {
  padding-bottom: 3rem;
}

.tab-bar {
  background-color: #d0262f;
  height: 5.5rem;
  margin-bottom: 1rem;

  @media #{$large-up} {
    height: 8.5rem;
  }

  .menu-icon {
    float: right;
    margin: 0.5rem;

    span {
      float: right;
      padding-right: 2.5rem;
      text-transform: uppercase;
      font-size: .8rem;

      &:hover {
        color: #ccc;
      }
    }
  }

  .right-small {
    border: 0;
    margin-top: 1.1rem;
  }

  .left-small {
    height: 100px;
    width: 100px;
    border: 0;
    margin-left: 1rem;
  }

  .row  {
    margin-top: .8rem;
  }

  img {
    width: 90px;
    margin-top: 0;
    float: left;

    @media #{$large-up} {
      width: 150px
    }

    &.social {
      margin: 1rem 1rem .2rem 0;
      float: right;
      width: 30px;
    }

    &.burger {
      margin: 1rem 0 0rem 1.5rem;
      float: right;
      width: 30px;

      @media #{$small-only} {
        margin: 1.8rem 0;
      }
    }
  }

  input {
    max-width: 300px;
    float: right;
    margin-top: .7rem;
    margin-right: 2.5rem;
  }

  @media #{$small-only} {
    .social, input {
      display: none !important;
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
    }
  }

  @media #{$medium-only} {
    .social, input {
      display: none !important;
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
    }
  }
}

.move-left .exit-off-canvas {
  box-shadow: none;
}

nav {
  &.shortcuts {
    display: none;

    @media #{$large-up} {
      display: block;
    }
  }

  .tab-bar-section.middle {
    left: -1.1875rem;
    right: -1.1875rem;
  }

  #lop {
    display: none;
  }

  ul {
    padding-left: 1rem;
  }
  li {
    padding: 2px 1px 0 1px!important;
    list-style: none;

    a {
      background-color: #ccc;
      text-align: center;
      width: 100%;
      display: block;
      padding: .5rem 0;
      color: #000;

      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}

.welcome-text {
  background-color: #aaa;
  margin-bottom: 1.5rem;
  padding: 5px 0;

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  span {
    color: #fff;
  }
}

// Front page and content views
#main-content .view-frontpage, .view-og-extras-nodes {
  .views-row {
    border-bottom: 5px solid #ccc;
    padding-bottom: .5rem;
    margin-bottom: 1rem;

    &.views-row-3, &.views-row-4, &.views-row-7, &.views-row-8 {
      @extend .large-12;
      @extend .columns;
      border-bottom: 0;
      padding-bottom: 0;

      @media #{$large-up} {
        h2 {
          font-size: 1.7rem;
        }

        &.views-row-3, &.views-row-7 {
          padding-left: 0;
        }

        &.views-row-4, &.views-row-8 {
          padding-right: 0;
        }
      }

      .views-field-name {
        font-size: .85rem;
      }
    }

    &.views-row-5, &.views-row-1 {
      clear: both;
      border-top: 5px solid #ccc;

      &:first-child {
        border-top: none;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    h2 {
      margin-top: .5rem;

      a {
        color: $body-font-color;
      }
    }

    .views-field-name {
      margin-bottom: .5rem;
    }

    .views-field-body {
      margin-bottom: .5rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      margin-bottom: 10px;
    }

    img {
      margin-bottom: 5px;
    }
  }

  .columns {
    img {
      margin-top: 1rem;
    }

    &.left {
      padding-right: .5rem;
      padding-left: 0;
    }

    &.right {
      padding-left: .5rem;
      padding-right: 0;
    }
  }
}

// Sidebar views
.sidebar {
  div[id*="views-kalender"], div[id*="views-forum"] {
    h2 {
      background-color: $primary-color;
      width: 100%;
      display: block;
      color: #fff;
      font-size: 1.2rem;
      text-transform: uppercase;
      padding: 0.5rem 0.5rem;
      margin-top: 0;
    }

    .views-row {
      margin-bottom: .5rem;
    }

    .views-field-title, .views-field-field-kategori {
      font-size: 0.8rem;
      font-weight: 600;
      line-height: 1rem;
    }

    .views-field-field-kategori {
      text-transform: uppercase;
    }

    .views-field-field-dato {
      font-size: 0.8rem;
    }
  }

  .more-link a, .view-footer a {
    width: 100%;
    display: block;
    background-color: #eee;
    color: $primary-color;
    padding: 0.6rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
    text-align: left;
    background-image: url(../img/arrow_left.svg);
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-x: 1rem;
    background-size: 1rem;
    transition: all .3s;

    &:hover {
      background-color: #ccc;
    }
  }

  div[id*="views-forum"] {
    margin-top: 2rem;

    h3 a {
      color: #000;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.4rem;
      margin-top: 1rem;
      display: block;
      margin-bottom: .5rem;
    }

    .sidecolumn-forum {
      border-bottom: 5px solid #ccc;
      padding-bottom: .5rem;

      &.last {
        border-bottom: 0;
      }
    }

    .views-field-body, .views-field-name {
      font-size: .8rem;
    }

    .views-field-body {
      a {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        display: block;
      }
    }

    .views-field-name {
      margin-top: .5rem;
    }
  }
}

#block-views-facebook-feed-block, #block-views-twitter-feed-block {
  margin-bottom: 2rem;

  > h2 {
    background: url(../img/fb-icon.svg) right .5rem center no-repeat #3b5998;
    background-size: auto 60%;
    height: 40px;
    display: block;
    width: 100%;
    text-indent: -9999px;
  }

  .views-row {
    background-color: #f7f7f7;
    margin: 3px 0;
    padding: .5rem;
    display: block;
    margin-bottom: 0;

    &, p {
      font-size: 0.8rem;
      font-weight: 500;
      color: #3b5998;
    }

    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

    }
  }
}

#block-views-facebook-feed-block {
  .views-row {
    a {
      color: #3b5998;
      display: block;
    }

    &:hover {
      background-color: #dfe3ee;
    }
  }
}

#block-views-twitter-feed-block {
  > h2 {
    background: url(../img/tw-icon.svg) right .5rem center no-repeat #55acee;
    background-size: auto 60%;
  }
}

#block-views-instagram-feed-block {
  overflow: hidden;

  > h2 {
    background: url(../img/in-icon.svg) right .5rem center no-repeat #3f729b;
    background-size: auto 60%;
    height: 40px;
    display: block;
    width: 100%;
    text-indent: -9999px;
    margin-bottom: 0;
  }

  .views-field-field-description {
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    padding: .5rem;
    color: white;
    font-size: .85rem;
    max-height: 76px;
    transition: all .5s ease-in-out;
    overflow: hidden;
  }

  &:hover .views-field-field-description {
    max-height: 300px;
  }

  .flexslider {
    box-shadow: none;
    border: none;

    .flex-direction-nav a {
      height: 50px;
    }
  }
}

.view-sponsors {
  margin: 1rem;
  display: block;

  img {
    margin: 2rem auto;
    display: block;
  }
}

.right-off-canvas-menu ul.off-canvas-list {
  li.has-submenu {
    a:before {
      display: none;
    }

    a:after {
      content: "";
      background-image: url(../img/arrow_left.svg);
      background-repeat: no-repeat;
      background-size: 1rem 1.4rem;
      margin-top: 0rem;
      margin-right: 0rem;
      float: right;
      width: 1rem;
      display: block;
      height: 2rem;
    }

    .back {
      a:after {
        background-image: url(../img/arrow_right.svg);
      }
    }
  }

  .social-mobile {
    a {
      width: 32%;
      display: inline-block;
      border: none;
      background: none;
      padding: 0.7rem 1.4rem .3rem 1.4rem;
    }
    img {
      margin: 0 auto;
      display: block;

      &.social {
        margin: 0;
      }
    }
  }

  li.no-submenu {
    a:before {
      display: none;
    }

    a:after {
      display: none;
    }
  }

  #block-search-form {
    padding: 0 1rem;
  }
}


.right-off-canvas-menu, .right-submenu {
  background: #eee;
  right: 1px;

  .back a {
    background: #eee;
  }

  ul.off-canvas-list li {

    label {
      color: #222;
      background: #ccc;
      border-top: 0;
    }

    a {
    border-bottom: 1px solid #ccc;
    color: #222;

      &:hover {
        background-color: #ccc;
      }
    }
  }
}

.move-left .exit-off-canvas {
  background: rgba(0, 0, 0, 0.4);

  &:hover {
  background: rgba(0, 0, 0, 0.4);
  }
}

.instagram-media {
  margin-bottom: 2rem !important;
}

// Node displays
.node {
  .submitted {
    margin-bottom: .5rem;
  }

  .user-picture {
    display: none;
  }
}

.page-node {
  #block-system-main > .content > .node > .content {
    border-bottom: 5px solid #ccc;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

.tabs {
  ul {
    margin-left: 0;
  }

  li {
    width: 50%;
    float: left;
    display: block;
    list-style-type: none;

    @media #{$medium-up} {
      float: none;
      width: 100%;
    }

    &.active {
      a {
        background: #ccc;
        color: #000;
      }
    }

    a {
      background-color: #333;
      color: #fff;
      padding: 2px 10px;
      text-transform: uppercase;
      display: block;
      margin: 0 1px 1px 0;

      @media #{$medium-up} {
        font-size: 1.2rem;
        margin: 0 0 .5rem;
      }

      &:hover {
        background-color: #ccc;
        color: #000;
      }
    }
  }

  &.secondary {
    li {
      a {
        background-color: transparent;
        color: $primary-color;
        text-transform: none;
        padding: 0px 0;
        font-size: 1rem;
        margin-top: -.5rem;
      }
    }
  }
}

// Node display: Groups
body[class*="page-node-"].og-context,
.page-node-trening, .page-node-faq, .page-node-members, .page-node-om, .page-node-forum {
  #page-title {
    font-size: 2.5rem;
    color: #d0262f;
    padding: 0;
  }
}

// Comments
#comments {
  margin-top: 2rem;

  h2 {
    font-size: 1.5rem;
  }

  .comment {
    border: 1px solid #ccc;
    padding: 0;
    margin-top: .5rem;

    .submitted, .content, .links {
      padding: .5rem;
    }

    .submitted {
      font-size: .8rem;
      background: #eee;
    }

    .permalink {
      display: none;
    }

    h3 {
      display: none;
    }

    .links.inline {
      font-size: .8rem;
      display: block;
      margin-bottom: 0;
    }
  }
}

.links.inline {
  margin-left: 0;

  li:first-child {
    padding-left: 0;
  }
}

// Forums
#forum {
  .icon {
    display: none;
  }

  .submitted {
    font-size: .75rem;
  }

  table {
    width: 100%;

    th {
      border-bottom-width: 1px;
    }

    tr {
      border-bottom: none;
    }

    tr.even {
      background-color: #fff;
    }
  }

  table[id*="forum-topic-"] {
    thead tr:first-child th:first-child {
      display: none;
    }
  }
}

// Search
#search-block-form {
  input[type="submit"] {
    display: none;
  }
}

.search-results {
  margin-left: 0;
  margin-top: 2rem;

  .search-result {
    border-bottom: 1px solid #ccc;

    .search-snippet-info {
      padding-left: 0;
    }
  }
}

// Special case for Styret
.page-node-10029 {
  .tabs {
    a[href*="forum"], a[href*="faq"], a[href*="trening"], a[href*="om"] {
      display: none;
    }
  }
}
